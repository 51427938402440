// ----- IMPORT
import $ from "jquery";
import AOS from "aos";
import cookieconsent from "cookieconsent";
import "magnific-popup/dist/jquery.magnific-popup";
// import "leaflet/dist/leaflet";

// ----- LIBS
// --- AOS
AOS.init({
    duration: 1200,
    once: true,
});
AOS.refresh();

// --- cookieconsent
window.addEventListener("load", function(){
    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#152e4f",
          "text": "#ffffff"
        },
        "button": {
          "background": "#0a1727",
          "text": "#ffffff"
        }
      },
      "theme": "edgeless",
      "content": {
        "message": "Bonjour, ce site utilise quelques cookies pour faciliter votre navigation.",
        "dismiss": "Ok !",
        "link": "En savoir plus",
        "href": "https://www.dnsbelgium.be/fr/privacy/rgpd"
      }
})});


// ----- ACTIONS
// --- burger
$(document).on('click','.navigation__burger',function(e) {
    // console.log('burger clicked');
    $('.navigation__list').toggleClass('open');
});

// --- scroll to
$(document).on('click','.navigation__list a',function(e) {
    e.preventDefault();
    
    var current_href = $(this).attr("href");
    $('html,body').animate({
        scrollTop: $(current_href).offset().top - 73 // h header
    }, 800);

    $( '.navigation__burger' ).click();
    // $('.navigation__list').removeClass('open'); // close burger

    $(this).blur(); // safety after click
});

// --- pages
var page = $('main').attr('id');

if ( page == 'home' ) {
	console.log('home');

    // --- gallery realisations
    $('.realisations__itemsContainer').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open    
        type: 'image',
        gallery:{
            enabled:true,
            tPrev: 'Projet précédent', // title for left button
            tNext: 'Projet suivant', // title for right button
            tCounter: '', // '<span class="mfp-counter">%curr% of %total%</span>' // markup of counter
        },
        type: 'image',
        image: {
            titleSrc: 'title', // this tells the script which attribute has your caption
            // titleSrc: function(item) {
            //     return '<a href="' + item.el.attr('href') + '">' + item.el.attr('title') + '</a>';
            // }
        },
        removalDelay: 300, // Delay in milliseconds before popup is removed
        mainClass: 'mfp-fade', // Class that is added to popup wrapper and background // make it unique to apply your CSS animations just to this exact popup
    });

    // --- map
    // leaflet
    var map = L.map('map', {
        scrollWheelZoom: false
    }).setView([50.5821322, 5.4634654], 11);

    // // osm
    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', { // default
    // // L.tileLayer('https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png', { // styles
    // // L.tileLayer('https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}.png', { // styles
    //     maxZoom: 18,
    //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // }).addTo(map)

    // mapbox
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    // L.tileLayer('https://api.mapbox.comstyles/v1/{username}/{style_id}/tiles/{tileSize}/{z}/{x}/{y}{@2x}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        // maxZoom: 18,
        id: 'mapbox/streets-v12', // https://docs.mapbox.com/api/maps/styles/        
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoibHVnYXNoIiwiYSI6ImNsazhpcWJyNzAzczYzZnFyeTB2dHV3bnIifQ.Cwt40DlWOkFllEzQW-TCSw',
        // style_id: 'cktkf96zj83es17p51jy2z8kj',
        // username: 'lugash'
    }).addTo(map);

    if ( $('.js-marker').length ) {
        var marker_path = $('.js-marker').attr('src');

        var myIcon = L.icon({
            iconUrl: marker_path,
            iconSize:     [32, 32], // size of the icon
            iconAnchor:   [16, 32], // point of the icon which will correspond to marker's location
            // popupAnchor:  [-3, -76] // ????// point from which the popup should open relative to the iconAnchor
        });
    
        var marker = L.marker([50.5821322, 5.4634654], {icon: myIcon}).addTo(map);
    
        marker.on('click', function(e){
            map.setView(e.latlng, 16);
        });
    }
}

if ( page == 'legal' ) {
	console.log('legal');
}
